enum Role {
    ADMIN = "FiveTool-Admin",
    REDIRECTS = "FiveTool-Redirects",
    MARKETING = "FiveTool-Marketing",
    PHOTO = "FiveTool-Photo",
    BULK_PHOTO = "FiveTool-Photo-Bulk-Uploads",
    FORGE = "FiveTool-Forge",
    AB = "FiveTool-AB",
    VSM_REPORTING = "FiveTool-VSM-Reporting",
    PLAYER_MUSIC = "FiveTool-Player-Music",
    P2P = "P2P-Redirects",
    USERS = "FiveTool-Users",
}

export default Role;
