import { useOktaAuth } from "@okta/okta-react";
import { AppContextState } from "../context/AppContext";
import Role from "../constants/Role";

export function getAuthorizations(state: AppContextState, requiredRole?: Role) {
    const access = {
        hasAdminAccess: false,
        hasRole: false,
        hasAppAccess: false,
    };

    const groups =
        state.userInfo && state.userInfo.groups
            ? (state.userInfo.groups as string[])
            : null;

    if (!groups) {
        return access;
    }

    access.hasAdminAccess = groups.includes(Role.ADMIN);
    access.hasRole = requiredRole && groups.includes(requiredRole);
    access.hasAppAccess = access.hasAdminAccess || access.hasRole;

    return access;
}

export function getOktaAccessToken() {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    return accessToken;
}
