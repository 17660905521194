import React, { PropsWithChildren } from "react";

import { useAppContext } from "../../context/AppContext";

import { getAuthorizations } from "../../utils/authorization";
import Role from "../../constants/Role";

type Props = PropsWithChildren<{
    appEnabled: boolean;
    requiredRole?: string;
    unauthorizedComponent?: React.FC;
}>;

export default function AppAuthorizationWrapper({
    children,
    requiredRole,
    unauthorizedComponent: UnauthorizedComponent,
    appEnabled,
}: Props) {
    const { state } = useAppContext();
    const { userInfo } = state;
    const { hasAppAccess } = getAuthorizations(state, requiredRole as Role);

    // Loading user info
    if (!userInfo) {
        return null;
    }

    if (!appEnabled) {
        if (UnauthorizedComponent) {
            return <UnauthorizedComponent />;
        }

        return null;
    }

    if (hasAppAccess) {
        return <>{children}</>;
    }

    if (UnauthorizedComponent) {
        return <UnauthorizedComponent />;
    }

    return null;
}
